/* eslint-disable no-unused-vars */
/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import {
  checkBookingAvailability,
  getAllTickets,
  getBus,
  getTicketpassengers,
  saveTicketBooking,
} from "../../dataSlice/dataSlice";
import {
  DELETE_BUS_TICKET_BOOKING,
  GET_BUS_INFO_DATE_TIME,
  GET_BUS_INFO_DETAIL_BY_BUS_ID,
  GET_SITESETTINGS,
} from "../../constant/constants";
import { toast } from "react-toastify";
import AgeRow from "./ageRow";
import { Button, Spinner } from "react-bootstrap";
import { Form, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const ageRows = [
  { title: "Adult ", ageRange: "18 - 99" },
  { title: "Youth", ageRange: "7 - 17" },
  { title: "Child", ageRange: "2 - 6" },
];

export const TourTypes = [
  { id: "FIT", name: "Family Individual Tour" },
  { id: "GIT", name: "Group Individual Tour" },
];
export const paymentTypes = [
  { id: "cash", name: "CASH" },
  { id: "bank", name: "BANK" },
];
const TicketSale = ({ passToggle }) => {
  const user_id = localStorage.getItem("user_id");
  const userType = localStorage.getItem("user_type");
  const dispatch = useDispatch();

  const buses = useSelector((state) => state.data.bus);
  const [busInfo, setBusInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [passengerFirstName, setPassengerFirstName] = useState("");
  const [passengerLastName, setPassengerLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [meetingPoint, setMeetingPoint] = useState("");
  const [travellersCount, setTravellersCount] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [bankHolderName, setBankHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [txnNo, setTxnNo] = useState("");

  const [seatNo, setSeatNo] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [bookingSeat, setBookingSeat] = useState([]);
  const [passengerSeat, setPassengerSeat] = useState([]);
  const [firstSeat, setFirstSeat] = useState([]);
  const [corridorSeat, setCorridorSeat] = useState([]);
  const [middleSeat, setMiddleSeat] = useState([]);
  const [lastSeat, setLastSeat] = useState([]);
  const [bookingNo, setBookingNo] = useState({});
  const [bookingLoading, setBooingLoading] = useState(false);
  const [bookingReady, setBookingReady] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [busSearchData, setBusSearchData] = useState({
    date: "",
    time: "7:00 AM",
    bus: "",
    bus_tour_type: "",
  });
  const [siteSetting, setSiteSetting] = useState({});
  const initialCounts = [1, 0, 0];
  const [counts, setCounts] = useState(initialCounts);
  const total = counts.reduce((a, b) => a + b, 0);
  const adultCount = parseInt(counts[0]);
  const youthCount = parseInt(counts[1]);
  const childCount = parseInt(counts[2]);
  const [availityMessage, setVailityMessage] = useState("");
  const [availityBooking, setVailityBooking] = useState(false);
  const handleCountChange = (index, delta) => {
    const newCounts = [...counts];
    newCounts[index] += delta;
    setCounts(newCounts);
  };

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);
  const handleSaveBookingInfo = () => {
    const bookingInfoFIT = {
      date: busInfo?.date,
      booking_time: busInfo?.time,
      bus: busInfo?.id,
      seat_no: bookingSeat,
      first_name: passengerFirstName,
      meeting_point: meetingPoint,
      number_of_travellers: travellersCount,
      pickup_time: pickupTime,
      payment_type: paymentType,
      paid_amount: paidAmount,
      account_holder_name: bankHolderName,
      bank_name: bankName,
      txn_no: txnNo,
      last_name: passengerLastName,
      primary_phone: phoneNumber,
      email: email,
      member: user_id,
    };
    const bookingInfoGIT = {
      date: busInfo?.date,
      booking_time: busInfo?.time,
      bus: busInfo?.id,
      seat_no: bookingSeat,
      first_name: passengerFirstName,
      adult_seat_count: adultCount,
      youth_seat_count: youthCount,
      child_seat_count: childCount,
      last_name: passengerLastName,
      primary_phone: phoneNumber,
      payment_type: paymentType,
      paid_amount: paidAmount,
      account_holder_name: bankHolderName,
      bank_name: bankName,
      txn_no: txnNo,
      email: email,
      member: user_id,
    };

    setShowEmailModal(true);
    setBooingLoading(true);
    dispatch(
      saveTicketBooking(
        busInfo?.tour_type == "FIT" ? bookingInfoFIT : bookingInfoGIT
      )
    ).then((res) => {
      if (res.payload.status == 200 || 201) {
        handleGetBusInfo();
        dispatch(getTicketpassengers());
        setPassengerSeat(bookingSeat);
        setBookingSeat([]);
        dispatch(getAllTickets({}));

        toast.success(`Ticket Booked Successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setBooingLoading(false);
        passToggle("withdrawList");

        setVailityBooking(false);
        setBusSearchData({
          ...busSearchData,

          date: "",
        });
      }
    });
  };

  const handleDeleteBookingInfo = () => {
    fetch(
      `${DELETE_BUS_TICKET_BOOKING}${bookingNo?.bus_booking_id}`,

      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: localStorage.getItem("jwt_access_token"),
        },
      }
    ).then((res) => {
      if (res) {
        handleGetBusInfo();
        setDeleteConfirmation(false);
        setBookingNo({});
        setShowModal(false);
        dispatch(getAllTickets({}));
        toast.success(`Ticket Cancelled Successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };

  const handlePassengerInfoAdd = (seatNo) => {
    setBookingNo({});
    setSeatNo(seatNo);
    bookingNo?.bus_booking_id && setShowModal(true);
  };
  const handleGetBusBookingInfo = (bookinInfo) => {
    setBookingNo(bookinInfo);

    bookinInfo?.status == "booked" && setShowModal(true);
  };

  // Modal
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // After processing, you can reset the form and hide the modal

    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the bus dropdown when bus_tour_type changes
    if (name === "bus_tour_type") {
      setBusInfo({});
      setFirstSeat([]);
      setCorridorSeat([]);
      setLastSeat([]);
      setMiddleSeat([]);
      setBookingNo({});
      setShowModal(false);
      setBookingSeat([]);
      setVailityMessage("");
      const filteredBuses = buses.filter((bus) => bus.tour_type == value);
      const newBus = filteredBuses.length > 0 ? filteredBuses[0].id : ""; // Pick first bus if available
      setBusSearchData({
        ...busSearchData,
        [name]: value, // Update the tour type
        bus: newBus, // Update the bus based on new tour type
        date: "",
        time: value == "FIT" ? "7:30 AM" : "7:00 AM",
      });

      handleGetAvailableDates(newBus);
    } else if (name == "bus") {
      setBusInfo({});
      setFirstSeat([]);
      setCorridorSeat([]);
      setLastSeat([]);
      setMiddleSeat([]);
      setBookingNo({});
      setShowModal(false);
      setBookingSeat([]);
      setVailityMessage("");
      // Handle the bus change or other fields
      setBusSearchData({
        ...busSearchData,
        bus: value,
        date: "",
      });
      handleGetAvailableDates(value);
    } else {
      setBusSearchData({
        ...busSearchData,
        time: value,
      });
      handleGetBusInfo(busSearchData.date, value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the form submission logic
  };

  const [error, setError] = useState("");
  // Your available dates in "MM/DD/YYYY" format

  // Convert available dates to a set of timestamps for quick lookup
  const availableDatesSet = new Set(
    availableDates.map((date) => new Date(date).setHours(0, 0, 0, 0))
  );

  // const isValidDate = (date) => {
  //   const dayTimestamp = date.setHours(0, 0, 0, 0);
  //   return availableDatesSet.has(dayTimestamp);
  // };
  const isValidDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset today's time to 00:00:00
    const dayTimestamp = date.setHours(0, 0, 0, 0); // Reset the selected date's time to 00:00:00

    // Check if the date is today or later and exists in the availableDatesSet
    return (
      dayTimestamp >= today.getTime() && availableDatesSet.has(dayTimestamp)
    );
  };

  const handleDateChange = (newDate) => {
    // const newDate = format();

    if (isValidDate(newDate)) {
      setBusSearchData((prevState) => {
        const newState = { ...prevState, date: newDate };
        return newState;
      });
      dispatch(
        checkBookingAvailability({
          bus_id: busSearchData?.bus,
          date: moment(new Date(newDate)).format("YYYY-MM-DD"),
        })
      ).then((res) => {
        if (res.payload.data.is_booked) {
          setVailityBooking(false);
          setVailityMessage(res.payload.data.message);
        } else {
          setVailityBooking(true);
          setVailityMessage("");
        }
      });
      handleGetBusInfo(newDate, busSearchData.time);
      setError("");
    } else {
      setBusSearchData({ date: null });
      setError("Only Thursday, Saturday, and Sunday are allowed.");
    }
  };
  useEffect(() => {
    dispatch(getBus());
  }, [dispatch]);
  // useEffect(() => {
  //   if (busSearchData.date) {
  //     handleGetBusInfo();
  //   }
  // }, [busSearchData]);

  useEffect(() => {
    if (buses?.length > 0 && !bookingReady) {
      setBookingReady(true);
      const defaultBus = buses.find((bus) => bus.tour_type == TourTypes[1].id);
      setBusSearchData((prevData) => ({
        ...prevData,
        bus: defaultBus ? defaultBus.id : "",
        bus_tour_type: TourTypes[1].id,
        date: null,
        time: "7:00 AM",
      }));
      if (defaultBus) {
        handleGetAvailableDates(defaultBus.id);
      }
    }
  }, [buses, bookingReady]);

  const handleGetBusInfo = (newDate, time) => {
    fetch(
      `${GET_BUS_INFO_DATE_TIME}/${busSearchData?.bus}/${moment(
        new Date(newDate ? newDate : busSearchData?.date)
      ).format("YYYY-MM-DD")}/${time ? time : busSearchData?.time}`
    )
      .then((res) => res.json())
      .then((data) => {
        setBusInfo(data);
        setFirstSeat(data?.seat_info?.slice(0, 20));
        setCorridorSeat(data?.seat_info?.slice(20, 24));
        setMiddleSeat(data?.seat_info?.slice(24, data?.seat_info?.length - 5));
        setLastSeat(data?.seat_info?.slice(-5));
      });
  };

  // Function to generate time options with 30-minute intervals
  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour >= 12 ? "PM" : "AM";
        const formattedMinute = minute == 0 ? "00" : minute;
        const timeLabel = `${formattedHour}:${formattedMinute} ${period}`;
        const timeValue = `${hour
          .toString()
          .padStart(2, "0")}:${formattedMinute}`;
        timeOptions.push({ label: timeLabel, value: timeValue });
      }
    }
    return timeOptions;
  };

  const timeOptions = generateTimeOptions();

  const numRows = Math.ceil(firstSeat?.length / 4); // Calculate the number of rows needed

  const getRowData = (rowIndex) => {
    const start = rowIndex * 4;

    return firstSeat?.slice(start, start + 4);
  };

  const toggleSeat = (seat) => {
    setBookingSeat((prevSelectedSeats) => {
      if (prevSelectedSeats.includes(seat)) {
        setCounts(initialCounts);
        // Seat is already selected, so remove it
        toast.error(`${seat} Removed Successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return prevSelectedSeats.filter((s) => s !== seat);
      } else {
        setCounts(initialCounts);

        // Seat is not selected, so add it
        toast.success(`${seat} Added Successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return [...prevSelectedSeats, seat];
      }
    });
  };

  const num2Rows = Math.ceil(corridorSeat?.length / 2); // Calculate the number of rows needed

  const getRow2Data = (rowIndex) => {
    const start = rowIndex * 2;

    return corridorSeat?.slice(start, start + 2);
  };

  const num3Rows = Math.ceil(middleSeat?.length / 4); // Calculate the number of rows needed

  const getRow3Data = (rowIndex) => {
    const start = rowIndex * 4;

    return middleSeat?.slice(start, start + 4);
  };
  const num4Rows = Math.ceil(lastSeat?.length / 5); // Calculate the number of rows needed

  const getRow4Data = (rowIndex) => {
    const start = rowIndex * 5;

    return lastSeat?.slice(start, start + 5);
  };
  const num6Rows = Math.ceil(corridorSeat?.length / 4); // Calculate the number of rows needed

  const getRow6Data = (rowIndex) => {
    const start = rowIndex * 4;

    return corridorSeat?.slice(start, start + 4);
  };

  const handleGetAvailableDates = (data) => {
    fetch(
      `${GET_BUS_INFO_DETAIL_BY_BUS_ID}/${data ? data : busSearchData?.bus}`
    )
      .then((res) => res.json())
      .then((data) => {
        setAvailableDates(data.available_dates);
      });
  };

  console.log("busInfo", busInfo);
  return (
    <>
      <div className="container ">
        {/* Date and Time Select */}
        <div>
          <div className="container mt-4">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="row mx-3">
                  <div className="form-group col-md-3">
                    <label>Tour Type:</label>
                    <select
                      className="form-control"
                      name="bus_tour_type"
                      value={
                        busSearchData.bus_tour_type ||
                        busSearchData?.bus_tour_type
                      }
                      onChange={handleChange}
                    >
                      <option value="">Select type</option>
                      {TourTypes?.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-3">
                    <label>
                      {busSearchData.bus_tour_type == "FIT"
                        ? "Tour Name:"
                        : "Bus:"}
                    </label>
                    <select
                      className="form-control"
                      name="bus"
                      value={busSearchData.bus || busSearchData?.bus}
                      onChange={handleChange}
                    >
                      <option value="">Select bus</option>
                      {buses
                        ?.filter(
                          (bus) => bus.tour_type == busSearchData.bus_tour_type
                        ) // Filter buses based on selected tour type
                        .map((bus) => (
                          <option
                            key={bus.id}
                            value={bus.id}
                            disabled={bus.bus_disable}
                          >
                            {bus?.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* <div className="form-group col-md-3">
                    <label>Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={busSearchData.date}
                      onChange={handleChange}
                    />
                  </div> */}
                  <Form.Group as={Col} md="3" controlId="formDate">
                    <Form.Label style={{ marginBottom: "0px" }}>
                      Date:
                    </Form.Label>
                    <DatePicker
                      selected={busSearchData?.date}
                      onChange={handleDateChange}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a date"
                      filterDate={isValidDate}
                      dayClassName={(date) =>
                        isValidDate(date) ? "valid-date" : "invalid-date"
                      }
                    />
                    {error && <small className="text-danger">{error}</small>}
                    <style jsx>{`
                      .invalid-date {
                        pointer-events: none;
                        opacity: 0.5;
                      }
                      .valid-date {
                        pointer-events: auto;
                        opacity: 1;
                      }
                    `}</style>
                  </Form.Group>
                  <div className="form-group col-md-3">
                    <label>Time:</label>
                    <select
                      className="form-control"
                      name="time"
                      value={busSearchData.time || busSearchData.time}
                      onChange={handleChange}
                    >
                      <option value="">Select time</option>
                      {/* Default "Select time" option */}
                      {timeOptions.map((timeOption, index) => (
                        <option
                          key={index}
                          value={timeOption.label}
                          disabled={
                            (busSearchData.bus_tour_type === "GIT" &&
                              timeOption.label !== "7:00 AM") ||
                            (busSearchData.bus_tour_type === "FIT" &&
                              timeOption.label !== "7:30 AM" &&
                              timeOption.label !== "8:30 AM")
                          }
                          style={{
                            fontWeight:
                              (busSearchData.bus_tour_type === "GIT" &&
                                timeOption.label === "7:00 AM") ||
                              (busSearchData.bus_tour_type === "FIT" &&
                                (timeOption.label === "7:30 AM" ||
                                  timeOption.label === "8:30 AM"))
                                ? 800
                                : 500,
                            color:
                              (busSearchData.bus_tour_type === "GIT" &&
                                timeOption.label === "7:00 AM") ||
                              (busSearchData.bus_tour_type === "FIT" &&
                                (timeOption.label === "7:30 AM" ||
                                  timeOption.label === "8:30 AM"))
                                ? "green"
                                : "",
                          }}
                        >
                          {timeOption.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="col-md-3" style={{ paddingTop: "20px" }}>
                    <button
                      type="submit"
                      onClick={handleGetBusInfo}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className=" row mx-0 justify-content-between">
            {busInfo?.seat_info?.length < 0 && (
              <div className="col-md-5   mb-5 ">
                <p>There is no seat available for booking</p>
              </div>
            )}
            {busInfo?.seat_info?.length > 0 && busInfo?.tour_type == "GIT" && (
              <div className="col-md-5 shadow-lg  mb-5 bg-white rounded">
                <div
                  className="container my-4 mx-auto"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <table
                    width="100%"
                    cellPadding="2"
                    cellSpacing="2"
                    style={{
                      border: "2px solid black",
                      padding: "10px",
                    }}
                  >
                    <tbody>
                      <div
                        className="m-4 mt-3"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <table width="100%">
                          <tr className="mb-3">
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              <img
                                src="/seat Booked.png"
                                alt=""
                                className="mb-4 mx-3"
                                loading="lazy"
                                width="50"
                              />
                              <img
                                src="/seatAvaiable.png"
                                alt=""
                                className="mb-4 mx-3"
                                loading="lazy"
                                width="65"
                              />
                              <img
                                src="/seatSelectedpng.png"
                                alt=""
                                className="mb-4 mx-3"
                                loading="lazy"
                                width="50"
                              />
                              <img
                                src="/blocked.png"
                                alt=""
                                className="mb-4 mx-3"
                                loading="lazy"
                                width="50"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" className="bold  h5 ">
                              <b>{busInfo?.name}</b>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <b>Date :</b> {busInfo.date}
                            </td>
                          </tr>
                          {busInfo.tour_type == "FIT" && (
                            <tr>
                              <td colSpan="2">
                                <b>Group Size :</b> {busInfo.group_size}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="2">
                              <b>Total Seat :</b> {busInfo.seat_quantity}
                            </td>
                          </tr>
                          {busInfo.tour_type == "GIT" ? (
                            <>
                              <tr>
                                <td colSpan="4">
                                  <b>Adult Ticket Price :</b>
                                  {busInfo.adult_seat_price}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <b>Youth Ticket Price :</b>
                                  {busInfo.youth_seat_price}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <b>Child Ticket Price :</b>
                                  {busInfo.child_seat_price}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="4">
                                <b>Price :</b>
                                {busInfo.price}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="2">
                              <b>Time :</b> {busInfo.time}
                            </td>
                            <td colSpan="2">
                              <b>Available :</b>
                              <span className="text-success fw-800">
                                {busInfo.available_seats}
                              </span>
                            </td>
                          </tr>
                        </table>
                        <div className="mt-4 mb-4">
                          <b>Note: </b>
                          <b className="text-danger">
                            If the number of tourists is less than twenty pax
                            then the tour will be cancelled
                          </b>
                        </div>
                      </div>

                      <tr style={{ justifyContent: "center", display: "flex" }}>
                        <td colSpan="4">
                          <table id="seatTable" style={{}}>
                            <tr>
                              <td
                                style={{
                                  position: "relative", // Set the position to relative for proper positioning
                                  padding: "5px",
                                  cursor: "pointer",
                                  paddingRight: "100",
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <img
                                  src="/stearing.png"
                                  alt=""
                                  className="mb-4 mx-3"
                                  loading="lazy"
                                  width="65"
                                />
                              </td>

                              <td
                                style={{
                                  position: "relative", // Set the position to relative for proper positioning
                                  padding: "5px",
                                  cursor: "pointer",
                                  paddingRight: "100",
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                &nbsp;
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <table id="seatTable" style={{}} className="mx-auto">
                        {Array.from({ length: numRows }, (_, rowIndex) => (
                          <tr key={rowIndex} className="mx-auto">
                            {getRowData(rowIndex).map((seat, colIndex) => (
                              <>
                                <td
                                  key={seat?.seat_no}
                                  style={{
                                    position: "relative",
                                    padding: "5px",
                                    cursor: "pointer",
                                    paddingRight: colIndex == 1 ? "100px" : "0",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    seat?.passenger?.first_name &&
                                    `${seat?.passenger?.first_name}(${seat?.member?.first_name})`
                                  }
                                  onClick={() =>
                                    seat?.status == "booked"
                                      ? ""
                                      : userType == "promoter"
                                      ? ""
                                      : handlePassengerInfoAdd(seat?.seat_no)
                                  }
                                >
                                  {seat?.seat_no && (
                                    <div
                                      onClick={() =>
                                        seat?.seat_no == "A3" ||
                                        seat?.seat_no == "A4" ||
                                        seat?.seat_no == "03" ||
                                        seat?.seat_no == "04"
                                          ? ""
                                          : // seat?.member?.id == user_id &&
                                          seat?.status == "booked"
                                          ? handleGetBusBookingInfo(seat)
                                          : // :
                                          // seat?.member?.id !== user_id &&
                                          //   seat?.status == "booked"
                                          // ? ""
                                          userType == "promoter"
                                          ? ""
                                          : toggleSeat(seat?.seat_no)
                                      }
                                      style={{
                                        position: "absolute",
                                        left: colIndex == 1 ? "20%" : "55%",
                                        top: "20%",
                                        transform: "translateX(-50%)",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <b> {seat?.seat_no}</b>
                                    </div>
                                  )}
                                  {seat?.status == "booked" ? (
                                    <img
                                      src="/seanBooked.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : seat?.seat_no == "A3" ||
                                    seat?.seat_no == "A4" ||
                                    seat?.seat_no == "03" ||
                                    seat?.seat_no == "04" ? (
                                    <img
                                      src="/block.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : bookingSeat.includes(seat?.seat_no) ? (
                                    <img
                                      src="/seatSelected.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : (
                                    <img
                                      src="/seatAvailable.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  )}
                                </td>
                              </>
                            ))}
                          </tr>
                        ))}
                      </table>

                      {busInfo?.seat_plan == "number" && (
                        <table id="seatTable" style={{}} className="mx-auto">
                          {Array.from({ length: num2Rows }, (_, rowIndex) => (
                            <tr key={rowIndex} className="mx-auto">
                              {getRow2Data(rowIndex).map((seat, colIndex) => {
                                return (
                                  <>
                                    <td
                                      key={seat?.seat_no}
                                      style={{
                                        position: "relative",
                                        padding: "5px",
                                        cursor: "pointer",
                                        paddingRight:
                                          colIndex == 1 ? "100px" : "0",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={
                                        seat?.passenger?.first_name &&
                                        `${seat?.passenger?.first_name}(${seat?.member?.first_name})`
                                      }
                                      onClick={() =>
                                        seat?.status == "booked"
                                          ? ""
                                          : userType == "promoter"
                                          ? ""
                                          : handlePassengerInfoAdd(
                                              seat?.seat_no
                                            )
                                      }
                                    >
                                      {seat?.seat_no && (
                                        <div
                                          onClick={() =>
                                            seat?.seat_no == "A3" ||
                                            seat?.seat_no == "A4" ||
                                            seat?.seat_no == "03" ||
                                            seat?.seat_no == "04"
                                              ? ""
                                              : // seat?.member?.id == user_id &&
                                              seat?.status == "booked"
                                              ? handleGetBusBookingInfo(seat)
                                              : // :
                                              // seat?.member?.id !== user_id &&
                                              //   seat?.status == "booked"
                                              // ? ""
                                              userType == "promoter"
                                              ? ""
                                              : toggleSeat(seat?.seat_no)
                                          }
                                          style={{
                                            position: "absolute",
                                            left: colIndex == 1 ? "20%" : "55%",
                                            top: "20%",
                                            transform: "translateX(-50%)",
                                            padding: "5px 5px",
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <b> {seat?.seat_no}</b>
                                        </div>
                                      )}
                                      {seat?.status == "booked" ? (
                                        <img
                                          src="/seanBooked.png"
                                          alt=""
                                          loading="lazy"
                                          width="50"
                                        />
                                      ) : seat?.seat_no == "A3" ||
                                        seat?.seat_no == "A4" ||
                                        seat?.seat_no == "03" ||
                                        seat?.seat_no == "04" ? (
                                        <img
                                          src="/block.png"
                                          alt=""
                                          loading="lazy"
                                          width="50"
                                        />
                                      ) : bookingSeat.includes(
                                          seat?.seat_no
                                        ) ? (
                                        <img
                                          src="/seatSelected.png"
                                          alt=""
                                          loading="lazy"
                                          width="50"
                                        />
                                      ) : (
                                        <img
                                          src="/seatAvailable.png"
                                          alt=""
                                          loading="lazy"
                                          width="50"
                                        />
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                              <td
                                style={{
                                  position: "relative",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                className="border"
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <h2>W.C</h2>
                              </td>
                              <td
                                className="border text-white"
                                style={{
                                  position: "relative",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                W.C
                              </td>
                            </tr>
                          ))}
                        </table>
                      )}

                      {busInfo?.seat_plan == "alphabetic" && (
                        <table id="seatTable" style={{}} className="mx-auto">
                          {Array.from({ length: num6Rows }, (_, rowIndex) => (
                            <tr key={rowIndex} className="mx-auto">
                              {getRow6Data(rowIndex).map((seat, colIndex) => (
                                <>
                                  <td
                                    key={seat?.seat_no}
                                    style={{
                                      position: "relative",
                                      padding: "5px",
                                      cursor: "pointer",
                                      paddingRight:
                                        colIndex == 1 ? "100px" : "0",
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                      seat?.passenger?.first_name &&
                                      `${seat?.passenger?.first_name}(${seat?.member?.first_name})`
                                    }
                                    onClick={() =>
                                      seat?.status == "booked"
                                        ? ""
                                        : userType == "promoter"
                                        ? ""
                                        : handlePassengerInfoAdd(seat?.seat_no)
                                    }
                                  >
                                    {seat?.seat_no && (
                                      <div
                                        onClick={() =>
                                          seat?.seat_no == "A3" ||
                                          seat?.seat_no == "A4" ||
                                          seat?.seat_no == "03" ||
                                          seat?.seat_no == "04"
                                            ? ""
                                            : // seat?.member?.id == user_id &&
                                            seat?.status == "booked"
                                            ? handleGetBusBookingInfo(seat)
                                            : // :
                                            // seat?.member?.id !== user_id &&
                                            //   seat?.status == "booked"
                                            // ? ""
                                            userType == "promoter"
                                            ? ""
                                            : toggleSeat(seat?.seat_no)
                                        }
                                        style={{
                                          position: "absolute",
                                          left: colIndex == 1 ? "20%" : "55%",
                                          top: "20%",
                                          transform: "translateX(-50%)",
                                          padding: "5px 5px",
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <b> {seat?.seat_no}</b>
                                      </div>
                                    )}
                                    {seat?.status == "booked" ? (
                                      <img
                                        src="/seanBooked.png"
                                        alt=""
                                        loading="lazy"
                                        width="50"
                                      />
                                    ) : seat?.seat_no == "A3" ||
                                      seat?.seat_no == "A4" ||
                                      seat?.seat_no == "03" ||
                                      seat?.seat_no == "04" ? (
                                      <img
                                        src="/block.png"
                                        alt=""
                                        loading="lazy"
                                        width="50"
                                      />
                                    ) : bookingSeat.includes(seat?.seat_no) ? (
                                      <img
                                        src="/seatSelected.png"
                                        alt=""
                                        loading="lazy"
                                        width="50"
                                      />
                                    ) : (
                                      <img
                                        src="/seatAvailable.png"
                                        alt=""
                                        loading="lazy"
                                        width="50"
                                      />
                                    )}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </table>
                      )}

                      <table id="seatTable" style={{}} className="mx-auto">
                        {Array.from({ length: num3Rows }, (_, rowIndex) => (
                          <tr key={rowIndex} className="mx-auto">
                            {getRow3Data(rowIndex).map((seat, colIndex) => (
                              <>
                                <td
                                  key={seat?.seat_no}
                                  style={{
                                    position: "relative",
                                    padding: "5px",
                                    cursor: "pointer",
                                    paddingRight: colIndex == 1 ? "100px" : "0",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    seat?.passenger?.first_name &&
                                    `${seat?.passenger?.first_name}(${seat?.member?.first_name})`
                                  }
                                  onClick={() =>
                                    seat?.status == "booked"
                                      ? ""
                                      : userType == "promoter"
                                      ? ""
                                      : handlePassengerInfoAdd(seat?.seat_no)
                                  }
                                >
                                  {seat?.seat_no && (
                                    <div
                                      onClick={() =>
                                        // seat?.member?.id == user_id &&
                                        seat?.status == "booked"
                                          ? handleGetBusBookingInfo(seat)
                                          : // :
                                          // seat?.member?.id !== user_id &&
                                          //   seat?.status == "booked"
                                          // ? ""
                                          userType == "promoter"
                                          ? ""
                                          : toggleSeat(seat?.seat_no)
                                      }
                                      style={{
                                        position: "absolute",
                                        left: colIndex == 1 ? "20%" : "55%",
                                        top: "20%",
                                        transform: "translateX(-50%)",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <b> {seat?.seat_no}</b>
                                    </div>
                                  )}
                                  {seat?.status == "booked" ? (
                                    <img
                                      src="/seanBooked.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : bookingSeat.includes(seat?.seat_no) ? (
                                    <img
                                      src="/seatSelected.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : (
                                    <img
                                      src="/seatAvailable.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  )}
                                </td>
                              </>
                            ))}
                          </tr>
                        ))}
                      </table>
                      <table id="seatTable" style={{}} className="mx-auto">
                        {Array.from({ length: num4Rows }, (_, rowIndex) => (
                          <tr key={rowIndex} className="mx-auto">
                            {getRow4Data(rowIndex).map((seat, colIndex) => (
                              <>
                                <td
                                  key={seat?.seat_no}
                                  style={{
                                    position: "relative",
                                    padding: "5px",
                                    cursor: "pointer",
                                    paddingRight: "12px",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    seat?.passenger?.first_name &&
                                    `${seat?.passenger?.first_name}(${seat?.member?.first_name})`
                                  }
                                  onClick={() =>
                                    seat?.status == "booked"
                                      ? ""
                                      : userType == "promoter"
                                      ? ""
                                      : handlePassengerInfoAdd(seat?.seat_no)
                                  }
                                >
                                  {seat?.seat_no && (
                                    <div
                                      onClick={() =>
                                        // seat?.member?.id == user_id &&
                                        seat?.status == "booked"
                                          ? handleGetBusBookingInfo(seat)
                                          : // :
                                          // seat?.member?.id !== user_id &&
                                          //   seat?.status == "booked"
                                          // ? ""
                                          userType == "promoter"
                                          ? ""
                                          : toggleSeat(seat?.seat_no)
                                      }
                                      style={{
                                        position: "absolute",
                                        left: "45%",
                                        top: "20%",
                                        transform: "translateX(-50%)",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <b> {seat?.seat_no}</b>
                                    </div>
                                  )}
                                  {seat?.status == "booked" ? (
                                    <img
                                      src="/seanBooked.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : bookingSeat.includes(seat?.seat_no) ? (
                                    <img
                                      src="/seatSelected.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  ) : (
                                    <img
                                      src="/seatAvailable.png"
                                      alt=""
                                      loading="lazy"
                                      width="50"
                                    />
                                  )}
                                </td>
                              </>
                            ))}
                          </tr>
                        ))}
                      </table>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="col-md-6 ">
              {bookingSeat?.length > 0 && busInfo?.tour_type == "GIT" && (
                <div
                  className=" mt-4 w-100 shadow-lg  mb-5 bg-white rounded p-md-5"
                  style={{ justifyContent: "center" }}
                >
                  <h4 className="text-center py-2">
                    <span
                      className="text-white  p-2 mt-3 rounded "
                      style={{ backgroundColor: "#29395B" }}
                    >
                      Passenger Details
                    </span>
                  </h4>
                  <table
                    className="p-5"
                    width="100%"
                    style={{
                      padding: "10px",
                    }}
                  >
                    <div className="mx-3 mt-3">
                      <table className="w-100 px-5">
                        <tr>
                          <td colSpan="4" className="bold text-danger h5 ">
                            <b>Bus Name : {busInfo?.name}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Date :</b> {busInfo.date}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Group Size :</b> {busInfo.group_size}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Total Seat :</b> {bookingSeat?.length}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Time :</b> {busInfo.time}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <b>Seat No :</b>
                            {bookingSeat.map((seat) => (
                              <span
                                key={seat}
                                className="btn btn-success mx-1 mt-1"
                              >
                                {seat}
                              </span>
                            ))}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="mx-2 my-5">
                      <form onSubmit={handleFormSubmit}>
                        <table cellSpacing={3} className="w-100">
                          {busInfo.tour_type == "GIT" &&
                            ageRows.map((row, index) => (
                              <AgeRow
                                key={index}
                                title={row.title}
                                ageRange={row.ageRange}
                                count={counts[index]}
                                index={index}
                                total={total}
                                maxCount={bookingSeat.length}
                                onCountChange={handleCountChange}
                              />
                            ))}
                          <tr>
                            <td colSpan="4" style={{ padding: "0" }}>
                              <div
                                className="custom-border"
                                style={{
                                  border: "1px solid #000",
                                  padding: "10px",
                                  marginTop: "30px",
                                }}
                              >
                                <table
                                  style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      {busInfo.tour_type == "GIT" ? (
                                        <td
                                          colSpan="4"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Adult :
                                          <b>{` ${counts[0]} x ${busInfo?.adult_seat_price} =`}</b>
                                          <span
                                            className="mx-1"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {counts[0] *
                                              busInfo?.adult_seat_price}
                                          </span>
                                        </td>
                                      ) : (
                                        <td
                                          colSpan="4"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Per-person price :
                                          <b>{` ${busInfo?.group_size} x ${(
                                            parseInt(busInfo?.price, 10) /
                                            parseInt(busInfo?.group_size, 10)
                                          )?.toFixed(2)} =`}</b>
                                          <span
                                            className="mx-1"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {busInfo?.price}
                                          </span>
                                        </td>
                                      )}
                                    </tr>
                                    {counts[1] !== 0 && (
                                      <tr>
                                        <td
                                          colSpan="4"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Youth:
                                          <b>{` ${counts[1]} x ${busInfo?.youth_seat_price} =`}</b>
                                          <span
                                            className="mx-1"
                                            style={{
                                              marginTop: "15px",
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {counts[1] *
                                              busInfo?.youth_seat_price}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {counts[2] !== 0 && (
                                      <tr>
                                        <td
                                          colSpan="4"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Child:
                                          <b>{` ${counts[2]} x ${busInfo?.child_seat_price} =`}</b>
                                          <span
                                            className="mx-1"
                                            style={{
                                              marginTop: "15px",
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {counts[2] *
                                              busInfo?.child_seat_price}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td
                                        colSpan="4"
                                        style={{
                                          fontSize: "12px",
                                          borderTop: "1px solid #e9e1e1",
                                        }}
                                      >
                                        <b>Total Price</b>
                                        {busInfo.tour_type == "GIT" ? (
                                          <span
                                            className="mx-1"
                                            style={{
                                              marginTop: "5px",
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {`${
                                              counts[0] *
                                                busInfo?.adult_seat_price +
                                              counts[2] *
                                                busInfo?.child_seat_price +
                                              counts[1] *
                                                busInfo?.youth_seat_price
                                            }.00`}
                                          </span>
                                        ) : (
                                          <span
                                            className="mx-1"
                                            style={{
                                              marginTop: "5px",
                                              display: "inline-block",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {busInfo?.price}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr className="my-4">
                            <td>First Name</td>
                            <td>
                              <input
                                style={{
                                  marginTop: "15px",

                                  display: "inline-block",
                                }}
                                type="text"
                                className="form-control"
                                value={passengerFirstName}
                                onChange={(e) =>
                                  setPassengerFirstName(e.target.value)
                                }
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={passengerLastName}
                                onChange={(e) =>
                                  setPassengerLastName(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number</td>
                            <td>
                              <input
                                type="tel"
                                className="form-control"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Email</td>
                            <td>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Method:</td>
                            <td>
                              <select
                                className="form-control"
                                name="time"
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                              >
                                <option value="">Select method</option>
                                {/* Default "Select time" option */}
                                {paymentTypes.map((method, index) => (
                                  <option key={index} value={method.id}>
                                    {method.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Pay Amount</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={paidAmount}
                                onChange={(e) => setPaidAmount(e.target.value)}
                              />
                            </td>
                          </tr>
                          {paymentType == "bank" && (
                            <>
                              <tr>
                                <td>Bank Holder Name</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={bankHolderName}
                                    onChange={(e) =>
                                      setBankHolderName(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Bank Name</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={bankName}
                                    onChange={(e) =>
                                      setBankName(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Reference/TXN No</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={txnNo}
                                    onChange={(e) => setTxnNo(e.target.value)}
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        </table>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            type="submit"
                            onClick={handleSaveBookingInfo}
                            disabled={
                              (busInfo?.tour_type === "GIT" &&
                                (bookingSeat.length !== total ||
                                  !email ||
                                  !passengerFirstName ||
                                  !passengerLastName ||
                                  !phoneNumber ||
                                  !paymentType)) ||
                              (busInfo?.tour_type === "FIT" &&
                                (!passengerFirstName ||
                                  !passengerLastName ||
                                  !phoneNumber ||
                                  !meetingPoint ||
                                  !pickupTime ||
                                  !paymentType ||
                                  (paymentType == "bank"
                                    ? !bankHolderName ||
                                      !bankName ||
                                      !txnNo ||
                                      !paidAmount
                                    : !paidAmount)))
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </table>
                </div>
              )}
              {availityMessage && busInfo?.tour_type == "FIT" && (
                <div
                  className=" mt-4 w-100 shadow-lg  mb-5 bg-white rounded p-md-5"
                  style={{ justifyContent: "center" }}
                >
                  <p>{availityMessage}</p>
                </div>
              )}
            </div>
            <div className="col-md-6 ">
              {busInfo?.tour_type == "FIT" && availityBooking && (
                <div
                  className=" mt-4 w-100 shadow-lg  mb-5 bg-white rounded p-md-5"
                  style={{ justifyContent: "center" }}
                >
                  <h4 className="text-center py-2">
                    <span
                      className="text-white  p-2 mt-3 rounded "
                      style={{ backgroundColor: "#29395B" }}
                    >
                      Passenger Details
                    </span>
                  </h4>
                  <table
                    className="p-5"
                    width="100%"
                    style={{
                      padding: "10px",
                    }}
                  >
                    <div className="mx-3 mt-3">
                      <table className="w-100 px-5">
                        <tr>
                          <td colSpan="4" className="bold text-danger h5 ">
                            <b>Tour Name : {busInfo?.name}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Date :</b> {busInfo.date}
                          </td>
                        </tr>

                        {/* <tr>
                          <td colSpan="2">
                            <b>Total Seat :</b> {bookingSeat?.length}
                          </td>
                        </tr> */}
                        <tr>
                          <td colSpan="2">
                            <b>Time :</b> {busInfo.time}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Group Size :</b> {busInfo.group_size}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="mx-2 my-5">
                      <form onSubmit={handleFormSubmit}>
                        <table cellSpacing={3} className="w-100">
                          <tr>
                            <td colSpan="4" style={{ padding: "0" }}>
                              <div
                                className="custom-border"
                                style={{
                                  border: "1px solid #000",
                                  padding: "10px",
                                  marginTop: "30px",
                                }}
                              >
                                <table
                                  style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="4"
                                        style={{ fontSize: "12px" }}
                                      >
                                        Per-person price :
                                        <b>{` ${busInfo?.group_size} x ${(
                                          parseInt(busInfo?.price, 10) /
                                          parseInt(busInfo?.group_size, 10)
                                        )?.toFixed(2)} =`}</b>
                                        <span
                                          className="mx-1"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {busInfo?.price}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        colSpan="4"
                                        style={{
                                          fontSize: "12px",
                                          borderTop: "1px solid #e9e1e1",
                                        }}
                                      >
                                        <b>Total Price</b>

                                        <span
                                          className="mx-1"
                                          style={{
                                            marginTop: "5px",
                                            display: "inline-block",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {busInfo?.price}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr className="my-4">
                            <td>First Name</td>
                            <td>
                              <input
                                style={{
                                  marginTop: "15px",

                                  display: "inline-block",
                                }}
                                type="text"
                                className="form-control"
                                value={passengerFirstName}
                                onChange={(e) =>
                                  setPassengerFirstName(e.target.value)
                                }
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={passengerLastName}
                                onChange={(e) =>
                                  setPassengerLastName(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number</td>
                            <td>
                              <input
                                type="tel"
                                className="form-control"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Number of travellers</td>
                            <td>
                              <input
                                type="meeting_point"
                                className="form-control"
                                value={travellersCount}
                                onChange={(e) => {
                                  const value = e.target.value;

                                  // Check if the input is a number and within the valid range (1-8)
                                  if (
                                    /^\d+$/.test(value) &&
                                    Number(value) >= 1 &&
                                    Number(value) <= 8
                                  ) {
                                    setTravellersCount(value); // Set value if valid
                                  } else {
                                    setTravellersCount(""); // Set as empty or add error handling logic
                                    alert(
                                      "Please enter a number between 1 and 8"
                                    );
                                  }
                                }}
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Meeting Point</td>
                            <td>
                              <input
                                type="meeting_point"
                                className="form-control"
                                value={meetingPoint}
                                onChange={(e) =>
                                  setMeetingPoint(e.target.value)
                                }
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Pick Time:</td>
                            <td>
                              <select
                                className="form-control"
                                name="time"
                                value={pickupTime}
                                onChange={(e) => setPickupTime(e.target.value)}
                              >
                                <option value="">Select time</option>
                                {/* Default "Select time" option */}
                                {timeOptions.map((timeOption, index) => (
                                  <option key={index} value={timeOption.label}>
                                    {timeOption.label}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Method:</td>
                            <td>
                              <select
                                className="form-control"
                                name="time"
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                              >
                                <option value="">Select method</option>
                                {/* Default "Select time" option */}
                                {paymentTypes.map((method, index) => (
                                  <option key={index} value={method.id}>
                                    {method.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Pay Amount</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={paidAmount}
                                onChange={(e) => setPaidAmount(e.target.value)}
                              />
                            </td>
                          </tr>
                          {paymentType == "bank" && (
                            <>
                              <tr>
                                <td>Bank Holder Name</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={bankHolderName}
                                    onChange={(e) =>
                                      setBankHolderName(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Bank Name</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={bankName}
                                    onChange={(e) =>
                                      setBankName(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Reference/TXN No</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={txnNo}
                                    onChange={(e) => setTxnNo(e.target.value)}
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        </table>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            type="submit"
                            onClick={handleSaveBookingInfo}
                            disabled={
                              !passengerFirstName ||
                              !passengerLastName ||
                              !phoneNumber ||
                              !meetingPoint ||
                              !pickupTime ||
                              !paymentType ||
                              (paymentType == "bank"
                                ? !bankHolderName ||
                                  !bankName ||
                                  !txnNo ||
                                  !paidAmount
                                : !paidAmount)
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            minWidth: "300px", // Customize the width of the modal
            margin: "auto",
            borderRadius: "4px",
            padding: "20px",
          },
        }}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Passenger Details"
      >
        <h4 className="mb-5 text-center">
          <span
            className="text-white  p-2 rounded text-center"
            style={{ backgroundColor: "#29395B" }}
          >
            Passenger Details
          </span>
        </h4>
        {bookingNo?.bus_booking_id && (
          <div>
            <table className="w-100">
              <tr>
                <td>
                  <b>First Name : </b>
                </td>
                <td>{bookingNo?.passenger?.first_name}</td>
              </tr>
              <tr>
                <td>
                  <b>Last Name : </b>
                </td>
                <td>{bookingNo?.passenger?.last_name}</td>
              </tr>
              <tr>
                <td>
                  <b>Phone :</b>
                </td>
                <td>{bookingNo?.passenger?.primary_phone}</td>
              </tr>
              <tr>
                <td>
                  <b>Agent :</b>
                </td>
                <td>
                  {bookingNo?.member?.first_name}
                  {`${
                    bookingNo?.member?.last_name
                      ? bookingNo?.member?.last_name
                      : ""
                  } ${bookingNo?.member.id == user_id ? "( You )" : ""}`}
                </td>
              </tr>
            </table>
            {deleteConfirmation ? (
              <div className="mt-2">
                <p className="text-danger bold">
                  <b> Do you confirm to delete this Ticket ?</b>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-danger mx-2"
                    onClick={handleDeleteBookingInfo}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setShowModal(false);
                      setDeleteConfirmation(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  className="btn btn-success"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                {/*<button
                  // type='submit'
                  className="btn btn-danger mx-2"
                  onClick={() => setDeleteConfirmation(true)}
                >
                  Delete
                </button>*/}
              </div>
            )}
          </div>
        )}
      </Modal>

      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            minWidth: "300px", // Customize the width of the modal
            maxWidth: "600px", // Customize the width of the modal
            margin: "auto",
            borderRadius: "4px",
            padding: "20px",
            "@media (max-width: 600px)": {
              maxWidth: "300px", // Customize the maximum width for mobile devices
            },
          },
        }}
        isOpen={showEmailModal}
        // onRequestClose={() => setShowEmailModal(false)}
        contentLabel="Ticket Details"
      >
        <h4 className="mb-5 text-center">
          <span
            className="text-white  p-2 rounded text-center"
            style={{ backgroundColor: "#29395B" }}
          >
            Ticket Details
          </span>
        </h4>
        {bookingLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Booking Loading...
            </Button>
          </div>
        ) : (
          <div>
            <table className="w-100">
              <tr>
                <td>
                  <b>Passenger Name </b>
                </td>
                <td>
                  : {passengerFirstName || ""} {passengerLastName || ""}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Phone </b>
                </td>
                <td>: {phoneNumber}</td>
              </tr>
              <tr>
                <td>
                  <b>Email </b>
                </td>
                <td>: {email}</td>
              </tr>
              <tr>
                <td>
                  <b>Bus Name </b>
                </td>
                <td>: {busInfo?.name}</td>
              </tr>
              <tr>
                <td>
                  <b>Time </b>
                </td>
                <td>: {busInfo?.time}</td>
              </tr>
              {busInfo.tour_type == "FIT" && (
                <tr>
                  <td>
                    <b>Number of travellers </b>
                  </td>
                  <td>: {travellersCount}</td>
                </tr>
              )}
              {busInfo.tour_type == "GIT" && (
                <tr>
                  <td>
                    <b>Seat No </b>
                  </td>
                  <td>
                    :{" "}
                    {passengerSeat.map((seat) => (
                      <span key={seat} className="btn btn-success mx-1 mt-1">
                        {seat}
                      </span>
                    ))}
                  </td>
                </tr>
              )}
              <tr>
                {busInfo.tour_type == "GIT" ? (
                  <td colSpan="4" style={{ padding: "0" }}>
                    <div
                      className="custom-border"
                      style={{
                        border: "1px solid #000",
                        padding: "10px",
                        marginTop: "30px",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan="4" style={{ fontSize: "12px" }}>
                              Adult :
                              <b>{` ${counts[0]} x ${busInfo?.adult_seat_price} =`}</b>
                              <span className="mx-1">
                                {counts[0] * busInfo?.adult_seat_price}
                              </span>
                            </td>
                          </tr>
                          {counts[1] !== 0 && (
                            <tr>
                              <td colSpan="4" style={{ fontSize: "12px" }}>
                                Youth:
                                <b>{` ${counts[1]} x ${busInfo?.youth_seat_price} =`}</b>
                                <span
                                  className="mx-1"
                                  style={{
                                    marginTop: "15px",
                                    display: "inline-block",
                                  }}
                                >
                                  {counts[1] * busInfo?.youth_seat_price}
                                </span>
                              </td>
                            </tr>
                          )}
                          {counts[2] !== 0 && (
                            <tr>
                              <td colSpan="4" style={{ fontSize: "12px" }}>
                                Child:
                                <b>{` ${counts[2]} x ${busInfo?.child_seat_price} =`}</b>
                                <span
                                  className="mx-1"
                                  style={{
                                    marginTop: "15px",
                                    display: "inline-block",
                                  }}
                                >
                                  {counts[2] * busInfo?.child_seat_price}
                                </span>
                              </td>
                            </tr>
                          )}
                          <tr style={{ borderTop: "1px solid #e8e8e8" }}>
                            <td
                              colSpan="4"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              <b>Total Amount</b>
                              <span
                                className="mx-1"
                                style={{
                                  marginTop: "15px",
                                  display: "inline-block",
                                }}
                              >
                                {counts[0] * busInfo?.adult_seat_price +
                                  counts[2] * busInfo?.child_seat_price +
                                  counts[1] * busInfo?.youth_seat_price}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ fontSize: "12px" }}>
                              <b>Paid Amount =</b>
                              <span
                                className="mx-1"
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {paidAmount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ fontSize: "12px" }}>
                              <b>Total Due Amount =</b>
                              <span
                                className="mx-1"
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {counts[0] * busInfo?.adult_seat_price +
                                  counts[2] * busInfo?.child_seat_price +
                                  counts[1] * busInfo?.youth_seat_price -
                                  parseInt(paidAmount)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                ) : (
                  <div
                    className="custom-border"
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      marginTop: "30px",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan="4" style={{ fontSize: "12px" }}>
                            Per-person price :
                            <b>{` ${busInfo?.group_size} x ${(
                              parseInt(busInfo?.price, 10) /
                              parseInt(busInfo?.group_size, 10)
                            )?.toFixed(2)} =`}</b>
                            <span
                              className="mx-1"
                              style={{
                                display: "inline-block",
                                fontWeight: 700,
                              }}
                            >
                              {busInfo?.price}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #e8e8e8" }}>
                          <td colSpan="4" style={{ fontSize: "12px" }}>
                            <b>Total Amount =</b>
                            <span
                              className="mx-1"
                              style={{
                                marginTop: "15px",
                                display: "inline-block",
                              }}
                            >
                              {busInfo.price}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" style={{ fontSize: "12px" }}>
                            <b>Paid Amount =</b>
                            <span
                              className="mx-1"
                              style={{
                                display: "inline-block",
                              }}
                            >
                              {paidAmount}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" style={{ fontSize: "12px" }}>
                            <b>Total Due Amount =</b>
                            <span
                              className="mx-1"
                              style={{
                                display: "inline-block",
                              }}
                            >
                              {parseInt(busInfo.price) - parseInt(paidAmount)}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </tr>
            </table>
            <div className=" ">
              <p className="text-success bold text-center">
                <b>"Booking confirmed! 🎉🚌"</b>
              </p>

              <p className="text-center">
                For assistance, contact us at:
                <b>{siteSetting?.phone}</b>
              </p>
              <p className="text-center">Safe travels! 🚌✨ Thank you!</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-danger mx-2"
                  disabled={bookingLoading}
                  onClick={() => {
                    setShowEmailModal(false);
                    setPassengerFirstName("");
                    setPassengerSeat([]);
                    setPassengerLastName("");
                    setEmail("");
                    setPhoneNumber("");
                    setTravellersCount("");
                    setMeetingPoint("");
                    setPickupTime("");
                    setPaymentType("");
                    setPaidAmount("");
                    setBankHolderName("");
                    setBankName("");
                    setTxnNo("");
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
export default TicketSale;
