import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar2 from "../../components/Navbar2";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import { getAllmenus, getSiteSetting } from "../../dataSlice/dataSlice";

// Importing Pages
import ContactPage from "../ContactPage";
import DashboardPage from "../DashboardPage";
import ErrorPage from "../ErrorPage";
import ForgotPassword from "../ForgotPassword";
import LoginPage from "../LoginPage";
import ProfilePage from "../ProfilePage";
import SignUpPage from "../SignUpPage";
import ProfileShowPage from "../ProfileShowPage";
import SupportPage from "../SupportPage";

// Importing PrivateRoute
import PrivateRoute from "../PrivateRoute/PrivateRoute"; // Adjust the path based on your folder structure
import AboutPage from "../AboutPage";

const AllRoute = ({ logo }) => {
  const [homeId, setHomeId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
      })
      .catch(() => {});
    dispatch(getAllmenus());
    dispatch(getSiteSetting());
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <Navbar2
          Logo={logo}
          id={homeId}
          hclass={"wpo-header-style-4 text-nowrap"}
        />

        <Switch>
          {/* Public routes */}
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={SignUpPage} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/404" component={ErrorPage} />

          {/* Private routes, protected by PrivateRoute */}
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute path="/dashboard" component={DashboardPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/support" component={SupportPage} />
          <PrivateRoute path="/ProfileShowPage" component={ProfileShowPage} />
          <PrivateRoute path="/contact" component={ContactPage} />
          <PrivateRoute path="/about/:id" component={AboutPage} />

          {/* Redirect to 404 for unknown routes */}
          <Route path="*" render={() => <Redirect to="/404" />} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
