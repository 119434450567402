import React, { Fragment, useEffect, useState } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import PageTitle from "../../components/pagetitle";
import AboutS2 from "../../components/AboutS2";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";

const AboutPage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "About" ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <PageTitle pageTitle={"About Us"} pagesub={"About"} />
      <AboutS2 id={homeId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
