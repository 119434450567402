/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import { BASE_URL } from "../../constant/constants";
import { useHistory } from "react-router-dom";

const Header2 = (props) => {
  const [isSearchShow, setIsSearchShow] = useState(false);
  const history = useHistory();

  const searchHandler = () => {
    setIsSearchShow(!isSearchShow);
  };

  const clickHandler = () => {
    window.scrollTo(10, 0);
    const jwtAccessToken = localStorage.getItem("jwt_access_token");
    const userImage = localStorage.getItem("user_image");
    // Do something with jwtAccessToken and userImage
  };

  const todaysTime = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (localStorage.getItem("token_expire_time")) {
      const expire_time = localStorage.getItem("token_expire_time");
      const timeDifference = new Date(todaysTime) - new Date(expire_time);
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      const isExpired = daysDifference >= 30;
      if (isExpired) {
        sessionStorage.clear();
        localStorage.clear();
        history.push("/");
        window.location.reload();
      }
    }
  }, [todaysTime]);
  const jwtAccessToken = localStorage.getItem("jwt_access_token");
  const userImage = localStorage.getItem("user_image");
  return (
    <header id="header" className={props.topbarNone}>
      <div
        className={`wpo-site-header  ${props.hclass}`}
        style={{ color: "blue", position: "sticky" }}
      >
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu menu={props.menu} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={clickHandler}
                    className="navbar-brand"
                    to={`/dashboard`}
                  >
                    <img
                      style={{
                        width: "140px",
                        height: "80px",
                        marginLeft: "50px",
                      }}
                      loading="lazy"
                      src={`${BASE_URL}${props.Logo}`}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  {props.menu.map((n, index) => (
                    <ul className="nav navbar-nav mb-1 mb-lg-0" key={index}>
                      <li className="menu-item-has-children">
                        {n.name === "Home" ? (
                          <Link onClick={clickHandler} to={`/`}>
                            {n?.name === "Home" ? n.name : ""}
                          </Link>
                        ) : (
                          <Link
                            onClick={clickHandler}
                            to={`/${n.name.toLowerCase()}/${n.id}`}
                          >
                            <span className="text-primary"> {n.name}</span>
                          </Link>
                        )}

                        <ul
                          className="sub-menu"
                          style={{
                            display: n.children.length === 0 ? "none" : "block",
                          }}
                        >
                          {n.children.map((n, index) => (
                            <li key={index}>
                              <Link
                                onClick={clickHandler}
                                to={`/${n.name.toLowerCase()}/${n.id}`}
                              >
                                <span className="text-primary"> {n.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ))}

                  <ul className="nav navbar-nav mb-1 mb-lg-0 mx-3">
                    <li className="menu-item-has-children">
                      {jwtAccessToken ? (
                        <img
                          src={userImage}
                          alt=""
                          width="40"
                          height="40"
                          loading="lazy"
                          className="rounded-circle "
                        />
                      ) : (
                        <Link onClick={clickHandler} to="/login">
                          <i className="fa-regular fa-user text-primary"></i>
                        </Link>
                      )}
                      <ul
                        className="sub-menu"
                        style={{
                          display: jwtAccessToken ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link onClick={clickHandler} to="/ProfileShowPage">
                            Profile
                          </Link>
                        </li>

                        <li>
                          <Link onClick={clickHandler} to="/support">
                            Support
                          </Link>
                        </li>

                        <li>
                          <Link onClick={clickHandler} to="/dashboard">
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              localStorage.removeItem("jwt_access_token");
                              localStorage.removeItem("user_id");
                              localStorage.removeItem("user_email");
                              localStorage.removeItem("user_name");
                              localStorage.removeItem("user_role");
                              localStorage.removeItem("first_name");
                              localStorage.removeItem("last_name");
                              localStorage.removeItem("user_image");
                              localStorage.removeItem("street_address_one");
                              localStorage.removeItem("primary_phone");
                              localStorage.removeItem("refer_id");
                              setTimeout(() => {
                                window.location.reload();
                              }, 10);
                            }}
                            to="/"
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;
